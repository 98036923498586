// Colors
$white: #fff;

$text: #74708d;
$text-span: #73708D;
$text-lighten: #c0bdd0;
$text-darken: #615d7c;
$text-darken-more: #514d6a;

$gray: #d2d9e5;
$gray-lighten: #eef0f4;
$gray-lighten-more: #f2f4f8;
$gray-lighten-more-more: #f9fafc;
$gray-darken: #b8beca;
$gray-border: #e4e9f0;
$gray-menu: #99a9bd;
$gray-50: #fafafa;
$gray-100: #f5f5f5;
$gray_150: #EEEEEE;
$gray-300: #E0E0E0;
$gray-400: #DFE4ED;
$gray-500: #9e9e9e;
$gray-700: #616161;
$solid: #979797;

$purple: #504d6a;
$purple-dark: #4C59DD;
$purple-dark-hover: #7583eb;
$green: #62C35F;
$shopify-green: #95BF46;

$black: #081616;
$black-dark: #000000;
$black-lighten: #222034;
$black-lighten-more: #393749;

$navy-blue: #061F37;
$blue: #08f;
$blue-darken: #0072d6;
$cobalt-blue: #0A44AA;
$skyblue: #2396FF;
$aquamarine: #1CE9B6;
$violet: #6E1EFF;
$orange: #FE5723;
$yellow: yellow;
$red: #DD3444;

$menuBg: #edeff3;
$menuBg-darken: #000c17;
$spinner-color: #999;
$spinner-error-color: #fb434a;

// Accent colors
$default: #acb7bf;
$primary: #4b58dd;
$secondary: #6a7a84;
$success: #46be8a;
$info: #0887c9;
$warning: #FE7F16;
$danger: #ea366d;
$danger-dark: #e1176b;

$linearToColor: #4738c3;
$linearFromColor: #d71658;

// gap
$default_gap: 16px;

// border
$border_radius: 8px;

// gradient
$default_gradient: linear-gradient(270deg, rgba(225,23,107,1) 0%, rgba(86,78,206,1) 100%);
$blue_gradient: linear-gradient(270deg, rgba(18,96,230,1) 0%, rgba(35,150,255,1) 100%);

// Shadows
$card_shadow: 0 6px 8px rgba(0, 0, 0, 0.1), 0 4px 4px rgba($gray, 0.2);
$card_border: 1px solid rgba($gray, 0.4);
$tooltip_shadow: 0 4px 5px 0 rgba(0,11,20,0.06), 0 1px 10px 0 rgba(0,11,20,0.04), 0 2px 4px -1px rgba(0,11,20,0.14);

// Font Family
$base__font-family: 'Work Sans', sans-serif !important;
$title__font-family: 'Arvo', serif !important;
$title__font-weight: 400;
$semibold__font-weight: 600;
$subtitle__font-weight: 500;
$bold_font-weight: 700;

// Font Size
$base__font-size: 12;

// glotio alerts alpha
$alpha_glotio_alerts: 0.05;

// Convert value rem() sass mixin
@function rem($px, $base: $base__font-size) {
  @return #{floor(($px/$base) * 100) / 100}rem; // to REMs
  // comment code above and uncomment below for change REMs to PXs
  //@return #{$px}px;
}

// Transitions
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}
@mixin transition-very-slow() {
  transition: all 0.4s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}
// Transitions delay in ms
$height_collapse_delay: 250ms;

// Responsive utils
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$md-min-width: 992px;
$md-max-width: 991px;
$sm-min-width: 768px;
$sm-max-width: 767px;
$xs-min-width: 576px;
$xs-max-width: 575px;

$dashboard-mobile-max-width: 950px;
$subscriptions_upgrade_mobile: 1090px;

// Form utils
$form__item-height: 50px;

// component height
$top_bar-height: 80px;

// line heights
$banner_lh: 25px;

// layout
$height_subscriptions_actions: var(--height_subscriptions_actions);

// Responsive typography

@media screen and (max-width: $sm-max-width) {
  html {
    font-size: 10px;
  }
}

@media screen and (max-width: $xs-max-width) {
  html {
    font-size: 9px;
  }
}

@media screen and (min-width: 2000px) {
  html {
    font-size: 11px;
  }
}

:export {
  linearToColor: $linearToColor;
  linearFromColor: $linearFromColor;
  purpleDark: $purple-dark;
  warningColor: $warning;
  successColor: $success;
  errorColor: $danger-dark;
  disabled: $gray-500;
  collapseDelay: $height_collapse_delay
}
